import React, { useState, useEffect } from 'react'
import Header from '../components/Includes/Header'
import Transac_leave from './components/Transac_leave'


const Transaction_Leave = () => {
  
  return (
    <>
      <div><Header /></div>
      <div><Transac_leave /></div>
      </>
  )
}

export default Transaction_Leave